import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import HomePage from './components/HomePage/HomePage';
import AdsPage from './components/AdsPage/AdsPage';
import Register from './components/Register';
import Login from './components/Login';
import AdminLogin from './components/AdminLogin';
import UserDashboard from './components/UserDashboard/UserDashboard';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import NavigationBar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/AuthContext';
import ManageUsers from './components/ManageUsers/ManageUsers';
import ManageAds from './components/ManageAds/ManageAds';
import ManageCategories from './components/ManageCategories/ManageCategories';
import ManageSubcategories from './components/ManageSubcategories/ManageSubcategories';
import UserManageAds from './components/UserManageAds/UserManageAds';
import VisitStatistics from './components/Statistics/VisitStatistics';
import ReservationStatistics from './components/Statistics/ReservationStatistics';
import UserStats from './components/UserStats/UserStats';
import Notifications from './components/Notifications';
import ReservationDetails from './components/ReservationDetails';
import AutoRenewAds from './components/AutoRenewAds'; // Dodano
import HighlightedAds from './components/HighlightedAds'; // Dodano
import Contact from './components/Contact';
import ResetPassword from './components/ResetPassword';
import EditProfile from './components/EditProfile'; // Ispravan put do EditProfile komponente
import EditAdminProfile from './components/EditAdminProfile'; // Ispravan put do EditAdminProfile komponente


function App() {
  return (
    <AuthProvider>
      <PayPalScriptProvider options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "merchant-id": process.env.REACT_APP_PAYPAL_MERCHANT_ID,
        currency: "EUR",
        components: "buttons"
      }}>
        <Router>
          <NavigationBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/ads" element={<AdsPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/profile" element={<PrivateRoute role="user" />}>
              <Route path="" element={<EditProfile />} />
            </Route>
            <Route path="/admin/profile" element={<PrivateRoute role="administrator" />}>
              <Route path="" element={<EditAdminProfile />} />
            </Route>
            <Route path="/dashboard" element={<PrivateRoute role="user" />}>
              <Route path="" element={<UserDashboard />} />
              <Route path="manage-ads" element={<UserManageAds />} />
              <Route path="stats" element={<UserStats />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="reservation-details" element={<ReservationDetails />} />
              <Route path="auto-renew-ads" element={<AutoRenewAds />} /> {/* Dodano */}
              <Route path="highlighted-ads" element={<HighlightedAds />} /> {/* Dodano */}
            </Route>
            <Route path="/admin/dashboard" element={<PrivateRoute role="administrator" />}>
              <Route path="" element={<AdminDashboard />} />
            </Route>
            <Route path="/admin/manage-users" element={<PrivateRoute role="administrator" />}>
              <Route path="" element={<ManageUsers />} />
            </Route>
            <Route path="/admin/manage-ads" element={<PrivateRoute role="administrator" />}>
              <Route path="" element={<ManageAds />} />
            </Route>
            <Route path="/admin/manage-categories" element={<PrivateRoute role="administrator" />}>
              <Route path="" element={<ManageCategories />} />
            </Route>
            <Route path="/admin/manage-subcategories" element={<PrivateRoute role="administrator" />}>
              <Route path="" element={<ManageSubcategories />} />
            </Route>
            <Route path="/admin/statistics/visits" element={<PrivateRoute role="administrator" />}>
              <Route path="" element={<VisitStatistics />} />
            </Route>
            <Route path="/admin/statistics/reservations" element={<PrivateRoute role="administrator" />}>
              <Route path="" element={<ReservationStatistics />} />
            </Route>
            <Route path="/contact" element={<Contact />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </Router>
      </PayPalScriptProvider>
    </AuthProvider>
  );
}

export default App;
