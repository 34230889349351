import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode properly

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Definišite funkciju refreshAccessToken
const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) throw new Error('No refresh token available');
    
    console.log('Sending request to refresh access token with refresh token:', refreshToken);
    const response = await axios.post(process.env.REACT_APP_REFRESH_TOKEN_URL, {
      refreshToken,
    });
    console.log('Refresh token response:', response.data);
    localStorage.setItem('token', response.data.access_token);
    return response.data.access_token;
  } catch (error) {
    console.error('Refresh token failed', error);
    handleLogout();
    return null;
  }
};

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  const csrfToken = localStorage.getItem('csrfToken'); // Dohvati CSRF token

  // Provjeri je li token istekao i osvježi ga ako treba
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      console.log('Decoded token:', decodedToken);

      if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
        console.log('Token expired, attempting to refresh...');
        const newToken = await refreshAccessToken();
        if (newToken) {
          config.headers['Authorization'] = `Bearer ${newToken}`;
        }
      } else if (decodedToken.exp) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    } catch (error) {
      console.error('Invalid token', error);
      handleLogout();
    }
  }

  // Dodaj CSRF token ako postoji
  if (csrfToken) {
    config.headers['X-CSRF-Token'] = csrfToken;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// Funkcija za odjavu korisnika
const handleLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('csrfToken'); // Ukloni CSRF token prilikom odjave
  window.location.href = '/login'; // Preusmjeravanje na stranicu za prijavu
};

export default api;
