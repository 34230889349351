import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import api from './api';
import './Register.css';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    address: '',
    phone: '', // Novo polje za broj telefona
    profileImage: null
  });
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
        setErrorMessage('Došlo je do greške pri dohvaćanju CSRF tokena!');
      }
    };

    fetchCsrfToken();
  }, []);

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+?[0-9]{10,15}$/;
    return phoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!validatePassword(formData.password)) {
      setErrorMessage('Šifra mora imati najmanje 8 karaktera, jedno veliko slovo, jedno malo slovo, jedan broj i jedan specijalni znak.');
      return;
    }

    if (!validatePhone(formData.phone)) {
      setErrorMessage('Unesite važeći broj telefona sa najmanje 10 cifara.');
      return;
    }

    const formDataObj = new FormData();
    Object.keys(formData).forEach(key => {
      formDataObj.append(key, formData[key]);
    });

    try {
      const response = await api.post('/users/register', formDataObj, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
      });
      console.log('Registracija uspješna:', response.data);
      
      // Očisti formu i error poruku nakon uspješne registracije
      setFormData({
        username: '',
        email: '',
        password: '',
        address: '',
        phone: '',
        profileImage: null
      });
      setErrorMessage('');
      
      navigate('/login');
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Došlo je do greške pri registraciji!');
      }
      console.error('Došlo je do greške pri registraciji!', error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center">Formular za registraciju</h2><br />
          <Form onSubmit={handleRegister} className="register-form">
            <Form.Group controlId="formUsername">
              <Form.Label>Korisničko ime</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Unesite Vaše korisničko ime"
                value={formData.username}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Unesite Vaš email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Šifra</Form.Label>
              <InputGroup className="password-input-group">
                <Form.Control
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  placeholder="Unesite Vašu šifru"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  autoComplete="new-password"
                />
                <InputGroup.Text className="eye-icon" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Adresa</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Unesite Vašu adresu"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Telefon (npr.+38761123456)"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formProfileImage">
              <Form.Label>Profilna slika</Form.Label>
              <Form.Control
                type="file"
                name="profileImage"
                onChange={handleChange}
              />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Registracija
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
