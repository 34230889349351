import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form, ListGroup, Spinner } from 'react-bootstrap';
import ReactStars from 'react-rating-stars-component';
import { useAuth } from '../AuthContext';
import api from '../api';
import './ReviewModal.css'; // Dodaj CSS

const ReviewModal = ({ adId, show, handleClose }) => {
  const { authState } = useAuth();
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({ rating: 0, comment: '' });
  const [csrfToken, setCsrfToken] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false); // Dodano stanje za prikaz modala
  const [modalMessage, setModalMessage] = useState(''); // Poruka unutar modala
  const [modalVariant, setModalVariant] = useState(''); // Tip poruke (uspjeh ili greška)

  const fetchReviews = useCallback(async () => {
    try {
      const response = await api.get(`/reviews/${adId}`);
      setReviews(response.data);
    } catch (error) {
      console.error('There was an error fetching the reviews!', error);
    }
  }, [adId]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    if (adId && show) {
      fetchReviews();
      fetchCsrfToken();
    }
  }, [adId, show, fetchReviews]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview({ ...newReview, [name]: value });
  };

  const handleRatingChange = (newRating) => {
    setNewReview({ ...newReview, rating: newRating });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!authState.isAuthenticated) {
      setModalMessage('Morate biti prijavljeni da biste ostavili recenziju.');
      setModalVariant('danger');
      setShowModal(true);
      return;
    }

    if (!newReview.rating) {
      setModalMessage('Ocjena je obavezna.');
      setModalVariant('danger');
      setShowModal(true);
      return;
    }

    setSubmitted(true);

    try {
      await api.post(
        '/reviews',
        {
          adId,
          ...newReview,
        },
        {
          headers: {
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          withCredentials: true,
        }
      );
      setNewReview({ rating: 0, comment: '' });
      fetchReviews();
      setModalMessage('Recenzija je uspješno poslata!');
      setModalVariant('success');
      setShowModal(true);
    } catch (error) {
      console.error('There was an error submitting the review!', error);
      setModalMessage('Došlo je do greške prilikom slanja recenzije.');
      setModalVariant('danger');
      setShowModal(true);
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Recenzije</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup className="mb-4">
            {reviews.map((review, index) => (
              <ListGroup.Item key={index}>
                <strong>Rating:</strong> {review.rating}/5<br />
                <strong>Comment:</strong> {review.comment}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Ocjena</Form.Label>
              <ReactStars
                count={5}
                onChange={handleRatingChange}
                size={24}
                activeColor="#ffd700"
                value={newReview.rating}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Komentar</Form.Label>
              <Form.Control
                as="textarea"
                name="comment"
                value={newReview.comment}
                onChange={handleInputChange}
                rows="3"
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={submitted}>
              {submitted ? (
                <>
                  <Spinner animation="border" size="sm" role="status" className="me-2" />
                  Snimi recenziju...
                </>
              ) : (
                'Snimi recenziju'
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal za prikaz poruke o uspješnosti ili grešci */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered className="custom-message-modal">
        <Modal.Header closeButton>
          <Modal.Title>{modalVariant === 'success' ? 'Uspjeh' : 'Greška'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={modalVariant === 'success' ? 'bg-success text-white' : 'bg-danger text-white'}>
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Zatvori
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReviewModal;
