import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './UserDashboardNav.css'; // Pretpostavka je da css stilove stavimo ovdje

const UserDashboardNav = () => {
  const navigate = useNavigate();

  const handleNavigation = (path, buttonName) => {
    console.log(`Navigating to ${path} from ${buttonName}`);
    navigate(path);
  };

  return (
    <div className="button-container text-center mt-4 mb-4">
      <Row className="justify-content-center">
        {/* Prvi red dugmadi */}
        <Col xs={6} lg={4} className="mb-2">
          <Button
            variant="dark"
            className="w-100 btn-sm"
            onClick={() => handleNavigation('/', 'Pregled oglasa')}
          >
            Pregled oglasa
          </Button>
        </Col>
        <Col xs={6} lg={4} className="mb-2">
          <Button
            variant="primary"
            className="w-100 btn-sm"
            onClick={() => handleNavigation('/dashboard/highlighted-ads', 'Istaknuti oglasi')}
          >
            Istaknuti oglasi
          </Button>
        </Col>

        {/* Drugi red dugmadi */}
        <Col xs={6} lg={4} className="mb-2">
          <Button
            variant="secondary"
            className="w-100 btn-sm"
            onClick={() => handleNavigation('/dashboard/auto-renew-ads', 'Automatski obnovljivi oglasi')}
          >
            Obnovljivi oglasi
          </Button>
        </Col>
        <Col xs={6} lg={4} className="mb-2">
          <Button
            variant="info"
            className="w-100 btn-sm"
            onClick={() => handleNavigation('/dashboard/stats', 'Napredna statistika')}
          >
            Napredna statistika
          </Button>
        </Col>

        {/* Treći red dugmadi */}
        <Col xs={6} lg={4} className="mb-2">
          <Button
            variant="warning"
            className="w-100 btn-sm"
            onClick={() => handleNavigation('/dashboard/notifications', 'Notifikacije')}
          >
            Notifikacije
          </Button>
        </Col>
        <Col xs={6} lg={4} className="mb-2">
          <Button
            variant="success"
            className="w-100 btn-sm"
            onClick={() => handleNavigation('/dashboard/reservation-details', 'Detalji rezervacija')}
          >
            Detalji rezervacija
          </Button>
        </Col>

        {/* Četvrti red dugmadi */}
        <Col xs={12} lg={4} className="mb-2">
          <Button
            variant="primary"
            className="w-100 btn-sm"
            onClick={() => handleNavigation('/dashboard/manage-ads', 'Upravljanje oglasima')}
          >
            Upravljanje oglasima
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UserDashboardNav;
