import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {jwtDecode} from 'jwt-decode';
import api from './api';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    role: null,
    token: null,
    premiumExpirationDate: null,
  });
  const [csrfToken, setCsrfToken] = useState('');
  const [loading, setLoading] = useState(true); // Stanje za praćenje učitavanja

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        console.log('Fetching CSRF token...');
        const response = await api.get('/csrf-token', { withCredentials: true });
        console.log('CSRF Token fetched:', response.data.csrfToken);
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token', error);
      }
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log('Setting auth state from local storage:', decodedToken);
        setAuthState({
          isAuthenticated: true,
          user: decodedToken,
          role: decodedToken.role,
          token: token,
          premiumExpirationDate: decodedToken.premiumExpirationDate,
        });
      } catch (error) {
        console.error('Invalid token format:', error);
        logout();
      }
    } else {
      setAuthState({
        isAuthenticated: false,
        user: null,
        role: null,
        token: null,
        premiumExpirationDate: null,
      });
      console.log('No token found in local storage, skipping auth state initialization.');
    }
    setLoading(false); // Postavi loading na false nakon inicijalizacije authState
  }, []);

  const login = (token, refreshToken) => {
    try {
      if (typeof token !== 'string') {
        throw new Error('Token must be a string');
      }
      const decodedToken = jwtDecode(token);
      console.log('Decoded token:', decodedToken);
      localStorage.setItem('token', token);

      // Pohranjivanje refresh tokena zavisno od uloge
      const role = decodedToken.role;
      if (role === 'administrator') {
        localStorage.setItem('adminRefreshToken', refreshToken); // Pohrana refresh tokena za admina
        localStorage.removeItem('userRefreshToken'); // Uklanja user refresh token
      } else {
        localStorage.setItem('userRefreshToken', refreshToken); // Pohrana refresh tokena za usera
        localStorage.removeItem('adminRefreshToken'); // Uklanja admin refresh token
      }

      setAuthState({
        isAuthenticated: true,
        user: decodedToken,
        role: role,
        token: token,
        premiumExpirationDate: decodedToken.premiumExpirationDate,
      });

      console.log('Auth state after login:', {
        isAuthenticated: true,
        user: decodedToken,
        role: role,
        token: token,
        premiumExpirationDate: decodedToken.premiumExpirationDate,
      });
    } catch (error) {
      console.error('Invalid token format:', error);
      logout();
    }
  };

  const logout = () => {
    console.log('Logging out...');
    setAuthState({
      isAuthenticated: false,
      user: null,
      role: null,
      token: null,
      premiumExpirationDate: null,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('adminRefreshToken');
    localStorage.removeItem('userRefreshToken');
    console.log('Auth state after logout:', {
      isAuthenticated: false,
      user: null,
      role: null,
      token: null,
      premiumExpirationDate: null,
    });
  };

  const refreshAccessToken = useCallback(async () => {
    try {
      console.log('Attempting to refresh access token...');
      // Provjera za oba refresh tokena
      const refreshToken = localStorage.getItem('userRefreshToken') || localStorage.getItem('adminRefreshToken');
      if (!refreshToken) {
        console.error('No refresh token available');
        throw new Error('No refresh token available');
      }

      console.log('Sending request to refresh access token with refresh token:', refreshToken);
      console.log('Šaljem zahtjev za novi token...');

      const response = await api.post('/auth/refresh-token', { refreshToken }, {
        headers: { 'X-CSRF-Token': csrfToken },
        withCredentials: true,
      });

      console.log('Refresh token response:', response.data);

      const newAccessToken = response.data.access_token;
      localStorage.setItem('token', newAccessToken);
      console.log('New access token saved to localStorage:', newAccessToken);

      const decodedToken = jwtDecode(newAccessToken);
      setAuthState(prevState => {
        if (!prevState.isAuthenticated) {
          console.warn('Unexpected authState change: was not authenticated before refresh');
        }
        console.log('Updating auth state with new access token:', decodedToken);
        return {
          ...prevState,
          isAuthenticated: true,
          user: decodedToken,
          token: newAccessToken,
          premiumExpirationDate: decodedToken.premiumExpirationDate,
        };
      });

      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing access token', error);
      setAuthState({
        isAuthenticated: false,
        user: null,
        role: null,
        token: null,
        premiumExpirationDate: null,
      });
      localStorage.removeItem('token');
      localStorage.removeItem('userRefreshToken');
      localStorage.removeItem('adminRefreshToken');
      logout();
      return null;
    }
  }, [csrfToken]);

  useEffect(() => {
    const checkTokenExpiration = async () => {
      if (authState?.isAuthenticated) {
        const token = authState.token;
        if (token) {
          try {
            console.log('Provjerava validnost tokena...');
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            // Osvježi token 30 sekundi prije isteka
            if (decodedToken.exp - currentTime < 30) {
              console.log('Token almost expired, attempting to refresh...');
              const newAccessToken = await refreshAccessToken();
              if (!newAccessToken) {
                console.error('Failed to refresh access token, logging out...');
                logout();
              }
            }
          } catch (error) {
            console.error('Error checking token expiration', error);
            logout();
          }
        } else {
          console.log('No token found in localStorage, skipping automatic token validation.');
        }
      }
    };

    checkTokenExpiration();

    const intervalId = setInterval(() => {
      if (authState?.isAuthenticated && localStorage.getItem('token')) {
        console.log('Provjerava validnost tokena...');
        checkTokenExpiration();
      } else {
        console.log('User is not authenticated, skipping automatic token validation.');
      }
    }, 55000); // Provjerava svakih 55 sekundi

    return () => clearInterval(intervalId);
  }, [authState, refreshAccessToken]);

  return (
    <AuthContext.Provider value={{ authState, setAuthState, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
