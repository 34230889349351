import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Form, Modal, Alert } from 'react-bootstrap';
import api from '../api';

const ManageAds = () => {
  const [ads, setAds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    title: '',
    description: '',
    price: '',
    expires_at: '',
    category_id: '',
    category_name: '',
    address: '', // Dodano
    city: '' // Dodano
  });
  const [file, setFile] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await api.get('/ads', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          withCredentials: true,
        });
        setAds(response.data);
      } catch (error) {
        console.error('Error fetching ads:', error);
        setErrorMessage('Error fetching ads.');
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await api.get('/categories', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          withCredentials: true,
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setErrorMessage('Error fetching categories.');
      }
    };

    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', {
          withCredentials: true,
        });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
        setErrorMessage('Error fetching CSRF token.');
      }
    };

    fetchAds();
    fetchCategories();
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/ads/${id}`, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true,
      });
      setAds(ads.filter(ad => ad.id !== id));
      setSuccessMessage('Ad deleted successfully!');
    } catch (error) {
      console.error('Error deleting ad:', error);
      setErrorMessage('Error deleting ad.');
    }
  };

  const handleShow = (ad = {}) => {
    setFormData({
      id: ad.id || '',
      title: ad.title || '',
      description: ad.description || '',
      price: ad.price || '',
      expires_at: ad.expires_at ? ad.expires_at.split('T')[0] : '',
      category_id: ad.category ? ad.category.id : '',
      category_name: ad.category ? ad.category.name : '',
      address: ad.address || '', // Dodano
      city: ad.city || '' // Dodano
    });
    setErrorMessage(''); // Reset error message when showing modal
    setSuccessMessage(''); // Reset success message when showing modal
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFile(null); // Reset file input
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = categories.find(category => category.id === parseInt(e.target.value));
    setFormData({
      ...formData,
      category_id: selectedCategory.id,
      category_name: selectedCategory.name
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('price', formData.price);
    formDataToSend.append('expires_at', formData.expires_at);
    formDataToSend.append('category_id', formData.category_id);
    formDataToSend.append('address', formData.address); // Dodano
    formDataToSend.append('city', formData.city); // Dodano
    if (file) {
      formDataToSend.append('photos', file);
    }

    try {
      await api.patch(`/ads/${formData.id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true,
      });
      setSuccessMessage('Ad updated successfully!');
      setShow(false);
      const response = await api.get('/ads', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true,
      });
      setAds(response.data);
    } catch (error) {
      console.error('Error saving ad:', error);
      setErrorMessage('Error saving ad.');
    }
  };

  return (
    <Container>
      <h2 className="mt-4">Manage Ads</h2>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Description</th>
            <th>Price</th>
            <th>Expires At</th>
            <th>Category Name</th>
            <th>Address</th> {/* Dodano */}
            <th>City</th> {/* Dodano */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {ads.map(ad => (
            <tr key={ad.id}>
              <td>{ad.id}</td>
              <td>{ad.title}</td>
              <td>{ad.description}</td>
              <td>{ad.price}</td>
              <td>{ad.expires_at}</td>
              <td>{ad.category ? ad.category.name : ''}</td>
              <td>{ad.address}</td> {/* Dodano */}
              <td>{ad.city}</td> {/* Dodano */}
              <td>
                <Button variant="warning" onClick={() => handleShow(ad)}>Edit</Button>{' '}
                <Button variant="danger" onClick={() => handleDelete(ad.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Ad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} {/* Prikaz poruke o grešci */}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="expires_at">
              <Form.Label>Expires At</Form.Label>
              <Form.Control
                type="date"
                name="expires_at"
                value={formData.expires_at}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="category_id">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="category_id"
                value={formData.category_id}
                onChange={handleCategoryChange}
                required
              >
                <option value="">Select Category</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="photos">
              <Form.Label>Photos</Form.Label>
              <Form.Control
                type="file"
                name="photos"
                onChange={handleFileChange}
                multiple
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ManageAds;
