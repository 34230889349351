import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import api from './api'; // Import API module

const EditProfile = () => {
  const { authState } = useAuth();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    address: '',
    password: '',
    profileImage: null,
  });
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Dodano za poruke o greškama
  const [successMessage, setSuccessMessage] = useState(''); // Dodano za poruke o uspjehu
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', {
          withCredentials: true
        });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
        setErrorMessage('Error fetching CSRF token.');
      }
    };

    fetchCsrfToken();

    const fetchProfile = async () => {
      if (authState.isAuthenticated) {
        const userId = authState.user.sub; // Use the user ID from authState
        try {
          const response = await api.get(`/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          setFormData({
            username: response.data.username,
            email: response.data.email,
            address: response.data.address,
            password: '',
            profileImage: null, // Image will be handled separately
          });
        } catch (error) {
          console.error('Error fetching profile', error);
          setErrorMessage('Error fetching profile.');
        }
      }
    };

    fetchProfile();
  }, [authState.isAuthenticated, authState.user.sub]); // Add authState.user.sub to dependencies

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      profileImage: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = new FormData();
    updatedData.append('username', formData.username);
    updatedData.append('email', formData.email);
    updatedData.append('address', formData.address);
    if (formData.password) {
      updatedData.append('password', formData.password);
    }
    if (formData.profileImage) {
      updatedData.append('profileImage', formData.profileImage);
    }

    try {
      await api.put(`/users/${authState.user.sub}`, updatedData, {
        headers: {
          'X-CSRF-Token': csrfToken,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      setSuccessMessage('Profile updated successfully.');
      console.log('Profile updated successfully.');
      navigate('/profile'); // Redirect to profile page
    } catch (error) {
      console.error('There was an error updating the profile!', error);
      setErrorMessage('There was an error updating the profile.');
    }
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center">Edit Profile</h2>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} {/* Prikaz poruke o grešci */}
          {successMessage && <Alert variant="success">{successMessage}</Alert>} {/* Prikaz poruke o uspjehu */}
          <Form onSubmit={handleSubmit} className="edit-profile-form">
            <Form.Group controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Enter username"
                value={formData.username}
                onChange={handleChange}
                disabled // Onemogućeno editovanje korisničkog imena
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Enter address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter new password (optional)"
                value={formData.password}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formProfileImage">
              <Form.Label>Profile Image</Form.Label>
              <Form.Control
                type="file"
                name="profileImage"
                onChange={handleFileChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Update Profile
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditProfile;
