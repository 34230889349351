import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Container, Modal, Alert, Card } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import './UserStats.css'; // Import the updated CSS
import api from '../api';
import axios from 'axios';
import UserDashboardNav from '../UserDashboardNav';

const UserStats = () => {
  const { authState } = useAuth();
  const [stats, setStats] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [noReviewsMessage, setNoReviewsMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');

  const fetchCsrfToken = async () => {
    try {
      const response = await api.get('/csrf-token', { withCredentials: true });
      setCsrfToken(response.data.csrfToken);
    } catch (error) {
      console.error('There was an error fetching the CSRF token!', error);
    }
  };

  const trackPageVisit = useCallback(async () => {
    const { user } = authState;

    if (user && user.sub) {
      try {
        const visitorData = {
          isAuthenticated: true,
          visitorId: user.sub,
          userId: user.sub,
          interests: '',
        };

        const ipAddress = await axios
          .get('https://api.ipify.org?format=json')
          .then((response) => response.data.ip)
          .catch((error) => {
            console.error('Error fetching IP address:', error);
            return null;
          });

        await api.post(
          '/ad-visits/page-visits/track',
          { ...visitorData, ipAddress },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'X-CSRF-Token': csrfToken,
            },
            withCredentials: true,
          }
        );
      } catch (error) {
        console.error('Error tracking page visit:', error);
      }
    }
  }, [authState, csrfToken]);

  const fetchStats = useCallback(async () => {
    const { user } = authState;

    if (user && user.sub) {
      try {
        const responseStats = await api.get(`/ad-visits/statistics/premium/${user.sub}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const responseAds = await api.get(`/ads/user/own`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const adsWithStatus = responseAds.data.reduce((map, ad) => {
          map[ad.id] = {
            isHighlighted: ad.isHighlighted === true,
            autoRenew: ad.autoRenew === true,
          };
          return map;
        }, {});

        const statsWithReviewsCount = await Promise.all(
          responseStats.data.map(async (stat) => {
            const reviewsCountResponse = await api.get(`/reviews/count/${stat.adId}`);
            const adStatus = adsWithStatus[stat.adId] || { isHighlighted: false, autoRenew: false };

            return {
              ...stat,
              reviewsCount: reviewsCountResponse.data.count,
              isHighlighted: adStatus.isHighlighted,
              autoRenew: adStatus.autoRenew,
            };
          })
        );

        setStats(statsWithReviewsCount);
        // setSuccessMessage('Statistika uspješno dohvacena!'); // Komentarisana linija koja postavlja poruku o uspjehu
        // setTimeout(() => setSuccessMessage(''), 3000); // Komentarisano automatsko uklanjanje poruke nakon 3 sekunde
      } catch (error) {
        setErrorMessage('Došlo je do greške prilikom dohvatanja statistike.');
        setTimeout(() => setErrorMessage(''), 3000);
      }
    }
  }, [authState]);

  const fetchReviews = async (adId) => {
    try {
      const response = await api.get(`/reviews/${adId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setReviews(response.data);

      if (response.data.length === 0) {
        setNoReviewsMessage('Trenutno nemate recenzija za ovaj oglas');
      } else {
        setNoReviewsMessage('');
      }

      const averageRatingResponse = await api.get(`/reviews/average/${adId}`);
      setAverageRating(averageRatingResponse.data.averageRating);

      setShowModal(true);
    } catch (error) {
      setErrorMessage('Došlo je do greške prilikom dohvatanja recenzija.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (csrfToken && authState.user) {
      trackPageVisit();
    }
  }, [csrfToken, trackPageVisit, authState.user]);

  useEffect(() => {
    if (authState.user && authState.user.sub) {
      fetchStats();
    }
  }, [authState.user, fetchStats]);

  return (
    <Container className="mt-4">
      <UserDashboardNav />

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      <Card className="mt-4 user-stats-container">
        <Card.Header as="h2" className="text-center">
          Napredna Statistika
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive className="user-stats-table">
            <thead className="table-dark">
              <tr>
                <th>Naslov Oglasa</th>
                <th>Ukupne Posjete</th>
                <th>Jedinstveni Posjetioci</th>
                <th>Rezervacije</th>
                <th>Broj Recenzija</th>
                <th>Istaknuti</th>
                <th>Automatski Obnovljivi</th>
                <th>Akcije</th>
              </tr>
            </thead>
            <tbody>
              {stats.map((stat) => (
                <tr key={stat.adId}>
                  <td data-label="Naslov Oglasa">{stat.title}</td>
                  <td data-label="Ukupne Posjete">{stat.totalVisits}</td>
                  <td data-label="Jedinstveni Posjetioci">{stat.uniqueVisitors}</td>
                  <td data-label="Rezervacije">{stat.reservations}</td>
                  <td data-label="Broj Recenzija">{stat.reviewsCount}</td>
                  <td data-label="Istaknuti">{stat.isHighlighted ? 'Da' : 'Ne'}</td>
                  <td data-label="Automatski Obnovljivi">{stat.autoRenew ? 'Da' : 'Ne'}</td>
                  <td data-label="Akcije">
                    <Button variant="primary" onClick={() => fetchReviews(stat.adId)}>
                      Prikaži Recenzije
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>

          </Table>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Recenzije za oglas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {noReviewsMessage ? (
            <p>{noReviewsMessage}</p>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Korisnik</th>
                  <th>Ocjena</th>
                  <th>Komentar</th>
                  <th>Datum</th>
                </tr>
              </thead>
              <tbody>
                {reviews.map((review) => (
                  <tr key={review.id}>
                    <td>{review.user.username}</td>
                    <td>{review.rating}</td>
                    <td>{review.comment}</td>
                    <td>{new Date(review.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <div className="text-end">
            <strong style={{ color: averageRating < 3 ? 'red' : 'green' }}>
              Prosjek ocjena: {averageRating}
            </strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Zatvori
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserStats;
