import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ role }) => {
  const { authState } = useAuth();
  const location = useLocation();

  useEffect(() => {
    console.log('PrivateRoute authState:', authState);
    console.log('PrivateRoute location:', location.pathname);
  }, [authState, location]);
  
  if (!authState.isAuthenticated) {
    console.log('User not authenticated, redirecting to login page');
    if (role === 'administrator') {
      return <Navigate to="/admin/login" state={{ from: location }} />;
    }
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (role) {
    if (!authState.user) {
      console.error('authState.user is undefined');
      return <Navigate to="/" />;
    }
    if (authState.user.role !== role) {
      console.error(`User role does not match required role: expected ${role}, got ${authState.user.role}`);
      return <Navigate to="/" />;
    }
  }

  return <Outlet />;
};

export default PrivateRoute;
