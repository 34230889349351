import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import api from './api';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token', error);
        setErrorMessage('Došlo je do greške prilikom preuzimanja CSRF tokena.');
      }
    };

    fetchCsrfToken();
  }, []);

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (!validatePassword(newPassword)) {
      setErrorMessage('Šifra mora imati najmanje 8 karaktera, jedno veliko slovo, jedno malo slovo, jedan broj i jedan specijalni znak.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage('Šifre se ne podudaraju.');
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (!token) {
      setErrorMessage('Token nije pronađen.');
      return;
    }

    try {
      await api.post('/users/reset-password', 
        { token, newPassword },
        {
          headers: { 'X-CSRF-Token': csrfToken },
          withCredentials: true,
        }
      );
      setSuccessMessage('Vaša šifra je uspješno promijenjena. Sada se možete prijaviti.');
      setErrorMessage('');
      setTimeout(() => navigate('/login'), 3000);  // Automatski preusmjeravanje na login nakon 3 sekunde
    } catch (error) {
      setErrorMessage('Došlo je do greške. Pokušajte ponovo.');
      setSuccessMessage('');
    }
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center">Resetujte Vašu šifru</h2>
          <Form onSubmit={handleResetPassword}>
            <Form.Group controlId="formNewPassword">
              <Form.Label>Nova šifra</Form.Label>
              <InputGroup className="password-input-group">
                <Form.Control
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Unesite novu šifru"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <InputGroup.Text className="eye-icon" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formConfirmPassword" className="mt-3">
              <Form.Label>Potvrdite novu šifru</Form.Label>
              <InputGroup className="password-input-group">
                <Form.Control
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Ponovo unesite novu šifru"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <InputGroup.Text className="eye-icon" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
            {successMessage && <p className="text-success mt-3">{successMessage}</p>}
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Resetuj šifru
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
