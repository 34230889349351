import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import api from './api'; // Import API module

const EditAdminProfile = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    address: '',
    password: ''
  });
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Dodano za poruke o greškama
  const [successMessage, setSuccessMessage] = useState(''); // Dodano za poruke o uspjehu

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get('/auth/profile', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          withCredentials: true
        });
        console.log('Profile fetched:', response.data); // Dodano za ispis podataka profila
        // Postavljanje svih podataka iz odgovora u formData
        setFormData({
          username: response.data.username,
          email: response.data.email,
          address: response.data.address || '', // Provjeri da li postoji address
          password: '' // Ostaviti password polje praznim
        });
      } catch (error) {
        console.error('Error fetching profile', error);
        setErrorMessage('Error fetching profile.');
      }
    };

    const fetchCsrfToken = async () => {
      try {
        const csrfResponse = await api.get('/csrf-token', {
          withCredentials: true,
        });
        setCsrfToken(csrfResponse.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
        setErrorMessage('Error fetching CSRF token.');
      }
    };

    fetchProfile();
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put(`/administrators/${authState.user.sub}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-CSRF-Token': csrfToken,
        },
        withCredentials: true
      });
      setSuccessMessage('Profile updated successfully.');
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Error updating profile', error);
      setErrorMessage('Error updating profile.');
    }
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center">Edit Admin Profile</h2>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} {/* Prikaz poruke o grešci */}
          {successMessage && <Alert variant="success">{successMessage}</Alert>} {/* Prikaz poruke o uspjehu */}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Enter username"
                value={formData.username}
                onChange={handleChange}
                required
                disabled // Onemogućeno editovanje korisničkog imena
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Enter address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter new password"
                value={formData.password}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Save Changes
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditAdminProfile;
