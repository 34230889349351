import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { Table, Container } from 'react-bootstrap'; // Import Bootstrap komponenti
import api from '../api'; // Import api module

const ReservationStatistics = () => {
  const [totalReservations, setTotalReservations] = useState(0);
  const [reservationDetails, setReservationDetails] = useState([]);
  const { authState } = useAuth();

  useEffect(() => {
    const fetchTotalReservations = async () => {
      try {
        const csrfResponse = await api.get('/csrf-token', { withCredentials: true });

        const headers = {
          Authorization: `Bearer ${authState.token}`,
          'X-CSRF-Token': csrfResponse.data.csrfToken
        };

        const totalReservationsResponse = await api.get('/ad-visits/statistics/total-reservations', {
          headers,
          withCredentials: true,
        });
        setTotalReservations(totalReservationsResponse.data);
      } catch (error) {
        console.error("Error fetching total reservation statistics:", error);
      }
    };

    const fetchReservationDetails = async () => {
      try {
        const csrfResponse = await api.get('/csrf-token', { withCredentials: true });

        const headers = {
          Authorization: `Bearer ${authState.token}`,
          'X-CSRF-Token': csrfResponse.data.csrfToken
        };

        const reservationDetailsResponse = await api.get('/ad-visits/statistics/detailed-reservations', {
          headers,
          withCredentials: true,
        });
        setReservationDetails(reservationDetailsResponse.data);
      } catch (error) {
        console.error("Error fetching detailed reservations:", error);
      }
    };

    fetchTotalReservations();
    fetchReservationDetails();
  }, [authState.token]);

  return (
    <Container className="mt-5">
      <h2>Reservation Statistics</h2>
      <p>Total Reservations: {totalReservations}</p>

      <h3>Detailed Reservations</h3>
      {reservationDetails.length === 0 ? (
        <p>No reservation details available.</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Ad Title</th>
              <th>Reservation Date</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>
            {reservationDetails.map((reservation, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{reservation.ad.title}</td>
                <td>{new Date(reservation.reservation_date).toLocaleString()}</td>
                <td>{reservation.user.username}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default ReservationStatistics;
