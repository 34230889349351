import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Form, Modal, Image, Alert } from 'react-bootstrap';
import api from '../api';

const ManageCategories = () => {
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    description: '',
    parentId: '',
    imageUrl: ''
  });
  const [file, setFile] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/categories', {
          withCredentials: true,
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setErrorMessage('Error fetching categories.');
      }
    };

    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', {
          withCredentials: true,
        });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
        setErrorMessage('Error fetching CSRF token.');
      }
    };

    fetchCategories();
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/categories/${id}`, {
        withCredentials: true,
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      });
      setCategories(categories.filter(category => category.id !== id));
      setSuccessMessage('Category deleted successfully!');
    } catch (error) {
      console.error('Error deleting category:', error);
      setErrorMessage('Error deleting category.');
    }
  };

  const handleShow = (category = {}) => {
    setFormData({
      id: category.id || '',
      name: category.name || '',
      description: category.description || '',
      parentId: category.parent ? category.parent.id : '',
      imageUrl: category.imageUrl || ''
    });
    setErrorMessage(''); // Reset error message when showing modal
    setSuccessMessage(''); // Reset success message when showing modal
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFile(null); // Reset file input
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.description);

    // Ensure parentId is properly set
    const parentId = formData.parentId === '' ? null : formData.parentId;
    formDataToSend.append('parentId', parentId);

    if (file) {
      formDataToSend.append('image', file);
    }

    try {
      if (formData.id) {
        await api.patch(`/categories/${formData.id}`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-Token': csrfToken,
          },
          withCredentials: true,
        });
        setSuccessMessage('Category updated successfully!');
      } else {
        await api.post('/categories', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-Token': csrfToken,
          },
          withCredentials: true,
        });
        setSuccessMessage('Category created successfully!');
      }
      setShow(false);
      const response = await api.get('/categories', {
        withCredentials: true,
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error saving category:', error);
      setErrorMessage('Error saving category.');
      if (error.response) {
        console.error('Response data:', error.response.data);
      }
    }
  };

  return (
    <Container>
      <h2 className="mt-4">Manage Categories</h2>
      <Button className="mb-3" onClick={() => handleShow()}>Add Category</Button>
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Parent Category</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(category => (
            <tr key={category.id}>
              <td>{category.id}</td>
              <td>{category.name}</td>
              <td>{category.description}</td>
              <td>{category.parent ? category.parent.name : 'None'}</td>
              <td>
                {category.imageUrl ? (
                  <Image src={`${process.env.REACT_APP_BASE_URL}/slike_kategorija/${category.imageUrl}`} rounded style={{ width: '100px' }} />
                ) : 'No Image'}
              </td>
              <td>
                <Button variant="warning" onClick={() => handleShow(category)}>Edit</Button>{' '}
                <Button variant="danger" onClick={() => handleDelete(category.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{formData.id ? 'Edit Category' : 'Add Category'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} {/* Prikaz poruke o grešci */}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="parentId">
              <Form.Label>Parent Category</Form.Label>
              <Form.Control
                as="select"
                name="parentId"
                value={formData.parentId}
                onChange={handleChange}
              >
                <option value="">None</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileChange}
              />
            </Form.Group>
            {formData.imageUrl && (
              <div className="mb-3">
                <Image src={`${process.env.REACT_APP_BASE_URL}/slike_kategorija/${formData.imageUrl}`} rounded style={{ width: '100px' }} />
              </div>
            )}
            <Button variant="primary" type="submit" className="mt-3">
              {formData.id ? 'Save Changes' : 'Add Category'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ManageCategories;
