import React, { useEffect, useRef, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "../components/UserDashboard/UserDashboard.css"; // Dodajte CSS datoteku
import api from './api'; // Import API module

function Message({ content }) {
  return <p className="success-message">{content}</p>;
}

function CheckoutButton({ price, user, onSuccess }) {
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "EUR",
    components: "buttons",
    "merchant-id": process.env.REACT_APP_PAYPAL_MERCHANT_ID,
  };
  console.log("Ovo je ključ:", process.env.REACT_APP_PAYPAL_MERCHANT_ID);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const csrfTokenRef = useRef('');

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', {
          withCredentials: true,
        });
        csrfTokenRef.current = response.data.csrfToken;
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handleSuccess = async (orderData) => {
    if (!orderData || !orderData.purchase_units || !orderData.purchase_units[0] || !orderData.purchase_units[0].payments || !orderData.purchase_units[0].payments.captures || !orderData.purchase_units[0].payments.captures[0]) {
      setMessage('Invalid order data structure');
      setShowMessage(true);
      throw new Error('Invalid order data structure');
    }

    const transaction = orderData.purchase_units[0].payments.captures[0];
    setMessage(
      `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
    );
    console.log(
      "Capture result",
      orderData,
      JSON.stringify(orderData, null, 2)
    );
    setShowMessage(true); // Prikazivanje poruke o uspjehu
    onSuccess(); // Poziv funkcije onSuccess
  };

  return (
    <div className="App">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
            color: "gold",
            label: "paypal",
          }}
          createOrder={async () => {
            try {
              const response = await api.post('/paypal/create-order', {
                price: price,
                payer: {
                  name: {
                    given_name: "Test",
                  },
                  email_address: process.env.REACT_APP_PAYPAL_BUSINESS_EMAIL, // koristi email računa kupca
                },
                currency: 'EUR'
              }, {
                headers: {
                  'X-CSRF-Token': csrfTokenRef.current,
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                withCredentials: true,
              });

              const orderData = response.data;

              if (orderData.orderID) {
                return orderData.orderID;
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);

                throw new Error(errorMessage);
              }
            } catch (error) {
              console.error(error);
              setMessage(`Could not initiate PayPal Checkout...${error}`);
            }
          }}
          onApprove={async (data, actions) => {
            try {
              const response = await api.post('/paypal/capture-order', {
                orderId: data.orderID
              }, {
                headers: {
                  'X-CSRF-Token': csrfTokenRef.current,
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                withCredentials: true,
              });

              const orderData = response.data;
              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
              } else if (errorDetail) {
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`
                );
              } else {
                await handleSuccess(orderData);
              }
            } catch (error) {
              console.error(error);
              setMessage(
                `Sorry, your transaction could not be processed...${error}`
              );
            }
          }}
          onError={(err) => {
            console.error('An error occurred during the transaction', err);
            alert('An error occurred during the transaction: ' + err.message);
          }}
        />
      </PayPalScriptProvider>
      {showMessage && <Message content={message} />}
    </div>
  );
}

export default CheckoutButton;
