import React from 'react';
import { Container, Card } from 'react-bootstrap';
import './Contact.css'; // Import the CSS file

const Contact = () => {
  return (
    <Container className="contact-container mt-4">
      <Card className="contact-card shadow">
        <Card.Header as="h2" className="text-center contact-header">Kontakt informacije</Card.Header>
        <Card.Body className="contact-body">
          <div className="contact-item">
            <p><strong>Naziv firme:</strong></p>
            <p>BeeCode d.o.o.</p>
          </div>
          <div className="contact-item">
            <p><strong>Adresa:</strong></p>
            <p>Ramiza Salčina 17, Sarajevo</p>
          </div>
          <div className="contact-item">
            <p><strong>Email:</strong></p>
            <p><a href="mailto:info@beecode.ba">info@beecode.ba</a></p>
          </div>
          <div className="contact-item">
            <p><strong>Telefon:</strong></p>
            <p><a href="tel:+38761799104">+387 61 799 104</a></p>
          </div>
          <div className="contact-item">
            <p><strong>Web stranica:</strong></p>
            <p><a href="https://www.beecode.ba" target="_blank" rel="noopener noreferrer">www.beecode.ba</a></p>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Contact;
