import React, { useEffect, useState } from 'react';
import { useAuth } from '../components/AuthContext';
import { Table, Container, Card } from 'react-bootstrap';
import api from './api'; // Import API module
import './Notifications.css';
import UserDashboardNav from './UserDashboardNav'; // Uvozimo navigacijsku komponentu

const Notifications = () => {
  const { authState } = useAuth();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await api.get(`/notifications/${authState.user.sub}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    if (authState.user && authState.user.sub) {
      fetchNotifications();
    }
  }, [authState.user]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/\//g, '.');
  };

  const parseMessage = (notification) => {
    try {
      const parsedMessage = JSON.parse(notification.message.match(/{.*}/)[0]);
      if (parsedMessage.start_date && parsedMessage.end_date) {
        const formattedStartDate = formatDate(parsedMessage.start_date);
        const formattedEndDate = formatDate(parsedMessage.end_date);
        return (
          <>
            <div>Rezervacija od: {formattedStartDate} do {formattedEndDate}</div>
            <div>Ukupna cijena: {parsedMessage.total_price} KM</div>
            <div>Oglas: {notification.adTitle}</div>
            <div>Korisnik: {notification.userName}</div>
          </>
        );
      }
      return notification.message;
    } catch (error) {
      return notification.message;
    }
  };

  return (
    <Container className="mt-4">
      <UserDashboardNav />

      <Card className="mt-4 notifications-container">
        <Card.Header as="h2" className="text-center">
          Notifikacije
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive className="notifications-table">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Poruka</th>
                <th>Datum</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification) => (
                <tr key={notification.id}>
                  <td data-label="ID">{notification.id}</td>
                  <td data-label="Poruka">{parseMessage(notification)}</td>
                  <td data-label="Datum">{formatDate(notification.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Notifications;
