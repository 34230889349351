import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, InputGroup, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useAuth } from './AuthContext';
import api from './api';
import {jwtDecode} from 'jwt-decode';
import './Login.css';

const Login = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [email, setEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [emailSuccessMessage, setEmailSuccessMessage] = useState('');

  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token', error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/auth/login', formData, {
        headers: { 'X-CSRF-Token': csrfToken },
        withCredentials: true,
      });

      const access_token = response.data.access_token;
      const refresh_token = response.data.refresh_token;

      let decodedToken;
      try {
        decodedToken = jwtDecode(access_token);
      } catch (err) {
        throw new Error('Failed to decode token');
      }

      if (decodedToken && decodedToken.role === 'user') {
        localStorage.setItem('token', access_token);
        localStorage.setItem('userRefreshToken', refresh_token);
        localStorage.removeItem('adminRefreshToken');
        login(access_token, refresh_token);
        navigate('/dashboard');
      } else {
        throw new Error('Invalid user data');
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage('Invalid username or password.');
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setEmailErrorMessage('Molimo unesite vaš email.');
      return;
    }

    try {
      await api.post('/auth/forgot-password', { email }, {
        headers: { 'X-CSRF-Token': csrfToken },
        withCredentials: true,
      });
      setEmailSuccessMessage('Provjerite vaš email za daljnje upute.');
      setEmailErrorMessage('');
    } catch (error) {
      setEmailErrorMessage('Došlo je do greške. Pokušajte ponovo.');
      setEmailSuccessMessage('');
    }
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center">Prijava</h2>
          <Form onSubmit={handleLogin} className="login-form">
            <Form.Group controlId="formUsername">
              <Form.Label>Korisničko ime</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Unesite Vaše korisničko ime"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Šifra</Form.Label>
              <InputGroup className="password-input-group">
                <Form.Control
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  placeholder="Unesite Vašu šifru"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <InputGroup.Text className="eye-icon" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Prijava
            </Button>
            <div className="text-center mt-3">
              <Button variant="link" onClick={() => setShowForgotPasswordModal(true)}>
                Zaboravili ste šifru?
              </Button>
            </div>
          </Form>
        </Col>
      </Row>

      {/* Modal za zaboravljenu šifru */}
      <Modal show={showForgotPasswordModal} onHide={() => setShowForgotPasswordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Zaboravljena šifra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Email adresa</Form.Label>
              <Form.Control
                type="email"
                placeholder="Unesite vaš email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            {emailErrorMessage && <p className="text-danger">{emailErrorMessage}</p>}
            {emailSuccessMessage && <p className="text-success">{emailSuccessMessage}</p>}
            <Button variant="primary" className="w-100 mt-3" onClick={handleForgotPassword}>
              Pošalji
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Login;
