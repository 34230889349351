import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Form, Modal, Alert, Image, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Importujte useNavigate za navigaciju
import { useAuth } from '../AuthContext';
import './UserManageAds.css'; // Učitavanje CSS datoteke
import api from '../api'; // Import api module
import UserDashboardNav from '../UserDashboardNav'; // Import navigacije

const UserManageAds = () => {
  const { authState } = useAuth();
  const navigate = useNavigate(); // Dodajte navigate za navigaciju na druge stranice
  const [ads, setAds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [show, setShow] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false); // Dodano stanje za prikaz loader modala
  const [formData, setFormData] = useState({
    id: '',
    title: '',
    description: '',
    price: '',
    expires_at: '',
    category_id: '',
    subcategory_id: '',
    primaryPhotoIndex: -1,
    isHighlighted: false,
    autoRenew: false,
    address: '',
    city: '',
    sea_view: false,
    mountain_view: false,
    city_view: false,
    near_restaurant: false,
    near_park: false,
    near_beach: false,
    has_wifi: false,
    has_parking: false,
    has_pool: false,
    has_gym: false,
    number_of_rooms: '',
    number_of_bathrooms: '',
    vehicle_type: '',
    has_gps: false,
    has_child_seat: false,
    has_insurance: false,
    has_driver: false,
    hunting_type: '',
    has_hunting_guides: false,
    has_hunting_equipment: false,
    has_lodging: false,
  });

  const [files, setFiles] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [existingImages, setExistingImages] = useState([]);
  const [csrfToken, setCsrfToken] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Dodano stanje za grešku
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false); // Dodano stanje za praćenje učitavanja
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [existingVideo, setExistingVideo] = useState(null); // Novo stanje za trenutno video
  const [showConfirmDeleteVideoModal, setShowConfirmDeleteVideoModal] = useState(false); // Dodan modal za brisanje videa
  const [deleteVideoConfirmationCallback, setDeleteVideoConfirmationCallback] = useState(null); // Callback za brisanje videa

  const isPremium = authState.user ? authState.user.isPremium : false;

  const handleDeleteVideo = () => {
    setDeleteVideoConfirmationCallback(() => () => {
      setExistingVideo(null); // Obrisati video
      setShowConfirmDeleteVideoModal(false); // Zatvori modal
    });
    setShowConfirmDeleteVideoModal(true); // Prikaz modala
  };

  useEffect(() => {
    if (loading) {
      setShowLoadingModal(true);
    } else {
      setShowLoadingModal(false);
    }
  }, [loading]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true); // Postavljanje loading stanja na true na početku
        const csrfResponse = await api.get('/csrf-token', {
          withCredentials: true,
        });
        setCsrfToken(csrfResponse.data.csrfToken);

        const [adsResponse, categoriesResponse] = await Promise.all([
          api.get('/ads/user/own', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials: true,
          }),
          api.get('/categories', {
            withCredentials: true,
          }),
        ]);

        if (!adsResponse.data || !categoriesResponse.data) {
          throw new Error('Nije moguće dohvatiti podatke oglasa ili kategorija.');
        }

        setAds(adsResponse.data);
        setCategories(categoriesResponse.data.filter(category => category.name !== 'no_category'));

        checkForNotifications(adsResponse.data);
      } catch (error) {
        console.error('Greška prilikom dohvatanja početnih podataka:', error);
        setErrorMessage('Došlo je do greške prilikom dohvatanja podataka. Molimo pokušajte ponovo kasnije.');
      } finally {
        setLoading(false); // Postavljanje loading stanja na false nakon završetka
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (successMessage) {
      setMessageText(successMessage);
      setShowMessageModal(true);
      setTimeout(() => {
        setSuccessMessage('');
        setShowMessageModal(false);
      }, 3000);
    }
  }, [successMessage]);

  const checkForNotifications = (ads) => {
    const notifications = [];
    const currentDate = new Date();
    ads.forEach(ad => {
      const expiresAt = new Date(ad.expires_at);
      const diffTime = Math.abs(expiresAt - currentDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 3 && diffDays > 0) {
        notifications.push(`Oglas "${ad.title}" ističe za ${diffDays} dana.`);
      }
    });
    setNotifications(notifications);
  };

  const handleDelete = (id) => {
    setConfirmationCallback(() => () => deleteAd(id));
    setShowConfirmationModal(true);
  };

  const deleteAd = async (id) => {
    setLoading(true);
    try {
      await api.delete(`/ads/${id}`, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true,
      });
      setAds(ads.filter(ad => ad.id !== id));
      setSuccessMessage('Oglas uspješno obrisan.');
    } catch (error) {
      console.error('Greška prilikom brisanja oglasa:', error);
      setErrorMessage('Došlo je do greške prilikom brisanja oglasa. Molimo pokušajte ponovo kasnije.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageDelete = async (imageId) => {
    setLoading(true);
    try {
      await api.delete(`/ads/images/${imageId}`, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true,
      });
      setExistingImages(existingImages.filter(image => image.id !== imageId));
      setSuccessMessage('Slika uspješno obrisana.');
    } catch (error) {
      console.error('Greška prilikom brisanja slike:', error);
      setErrorMessage('Došlo je do greške prilikom brisanja slike. Molimo pokušajte ponovo kasnije.');
    } finally {
      setLoading(false);
    }
  };

  const handleShow = (ad = {}) => {
    setFormData({
      id: ad.id || '',
      title: ad.title || '',
      description: ad.description || '',
      price: ad.price || '',
      expires_at: ad.expires_at ? ad.expires_at.split('T')[0] : '',
      category_id: ad.category ? ad.category.id : '',
      subcategory_id: ad.subcategory ? ad.subcategory.id : '',
      primaryPhotoIndex: ad.images && ad.images.length > 0 ? ad.images.findIndex(image => image.is_primary) : -1,
      isHighlighted: ad.isHighlighted || false,
      autoRenew: ad.autoRenew || false,
      address: ad.address || '',
      city: ad.city || '',
      sea_view: ad.sea_view || false,
      mountain_view: ad.mountain_view || false,
      city_view: ad.city_view || false,
      near_restaurant: ad.near_restaurant || false,
      near_park: ad.near_park || false,
      near_beach: ad.near_beach || false,
      has_wifi: ad.has_wifi || false,
      has_parking: ad.has_parking || false,
      has_pool: ad.has_pool || false,
      has_gym: ad.has_gym || false,
      number_of_rooms: ad.number_of_rooms || '',
      number_of_bathrooms: ad.number_of_bathrooms || '',
      vehicle_type: ad.vehicle_type || '',
      has_gps: ad.has_gps || false,
      has_child_seat: ad.has_child_seat || false,
      has_insurance: ad.has_insurance || false,
      has_driver: ad.has_driver || false,
      hunting_type: ad.hunting_type || '',
      has_hunting_guides: ad.has_hunting_guides || false,
      has_hunting_equipment: ad.has_hunting_equipment || false,
      has_lodging: ad.has_lodging || false,
    });
    setExistingImages(ad.images || []);
    setFiles([]);
    setVideoFile(null);
    setExistingVideo(ad.videoUrl || null); // Postavi trenutno video ako postoji
    setShow(true);

    if (ad.category) {
      fetchSubcategories(ad.category.id);
    }
  };

  const handleClose = () => {
    setShow(false);
    setFiles([]);
    setVideoFile(null);
    setErrorMessage('');
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    });
  };

  const handleExpiresAtChange = (e) => {
    const maxDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    if (e.target.value > maxDate) {
      setErrorMessage('Datum isteka ne može biti više od mjesec dana unaprijed.');
      return;
    }
    setFormData({
      ...formData,
      expires_at: e.target.value
    });
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryId = parseInt(e.target.value);
    setFormData({
      ...formData,
      category_id: selectedCategoryId,
      subcategory_id: ''
    });
    fetchSubcategories(selectedCategoryId);
  };

  const handleSubcategoryChange = (e) => {
    setFormData({
      ...formData,
      subcategory_id: parseInt(e.target.value)
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (!isPremium && selectedFiles.length > 4) {
      setErrorMessage('Samo premium korisnici mogu dodati više od 4 slike.');
      return;
    }
    setFiles(selectedFiles);
    setErrorMessage('');

    // Postavi prvu sliku kao primarnu ako nije postavljena nijedna
    if (selectedFiles.length > 0 && formData.primaryPhotoIndex === -1) {
      setFormData({
        ...formData,
        primaryPhotoIndex: 0, // Postavljanje prve slike kao primarne
      });
    }
  };

  const handleVideoChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handlePrimaryPhotoChange = (index) => {
    setFormData({
      ...formData,
      primaryPhotoIndex: index
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log('Submitting ad form with data:', formData);
    console.log('Files:', files);
    console.log('Existing images:', existingImages);

    // Provjera broja slika za obične i premium korisnike
    if (!isPremium && files.length > 4) {
      setErrorMessage('Samo premium korisnici mogu dodati više od 4 slike.');
      setLoading(false);
      return;
    }

    const subcategoryId = formData.subcategory_id ? formData.subcategory_id : 12;

    // Kreiranje FormData objekta
    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('price', formData.price || 0);
    formDataToSend.append('expires_at', formData.expires_at || new Date().toISOString());
    formDataToSend.append('category_id', formData.category_id || '');
    formDataToSend.append('subcategory_id', subcategoryId);

    const primaryPhotoIndex = formData.primaryPhotoIndex !== -1 ? formData.primaryPhotoIndex : 0;
    formDataToSend.append('primaryPhotoIndex', primaryPhotoIndex);

    formDataToSend.append('isHighlighted', formData.isHighlighted ? 1 : 0);
    formDataToSend.append('autoRenew', formData.autoRenew ? 1 : 0);
    formDataToSend.append('address', formData.address || '');
    formDataToSend.append('city', formData.city || '');

    formDataToSend.append('number_of_rooms', formData.number_of_rooms || 0);
    formDataToSend.append('number_of_bathrooms', formData.number_of_bathrooms || 0);
    formDataToSend.append('vehicle_type', formData.vehicle_type || '');
    formDataToSend.append('hunting_type', formData.hunting_type || '');

    // Dodavanje boolean vrijednosti
    formDataToSend.append('sea_view', formData.sea_view ? 1 : 0);
    formDataToSend.append('mountain_view', formData.mountain_view ? 1 : 0);
    formDataToSend.append('city_view', formData.city_view ? 1 : 0);
    formDataToSend.append('near_restaurant', formData.near_restaurant ? 1 : 0);
    formDataToSend.append('near_park', formData.near_park ? 1 : 0);
    formDataToSend.append('near_beach', formData.near_beach ? 1 : 0);
    formDataToSend.append('has_wifi', formData.has_wifi ? 1 : 0);
    formDataToSend.append('has_parking', formData.has_parking ? 1 : 0);
    formDataToSend.append('has_pool', formData.has_pool ? 1 : 0);
    formDataToSend.append('has_gym', formData.has_gym ? 1 : 0);
    formDataToSend.append('has_gps', formData.has_gps ? 1 : 0);
    formDataToSend.append('has_child_seat', formData.has_child_seat ? 1 : 0);
    formDataToSend.append('has_insurance', formData.has_insurance ? 1 : 0);
    formDataToSend.append('has_driver', formData.has_driver ? 1 : 0);
    formDataToSend.append('has_hunting_guides', formData.has_hunting_guides ? 1 : 0);
    formDataToSend.append('has_hunting_equipment', formData.has_hunting_equipment ? 1 : 0);
    formDataToSend.append('has_lodging', formData.has_lodging ? 1 : 0);

    // Dodavanje novih slika
    files.forEach((file) => {
      formDataToSend.append('photos', file);
    });

    existingImages.forEach((image, index) => {
      formDataToSend.append(`existingImages[${index}]`, image.url);
    });

    // Dodavanje deleteVideo flag ako je korisnik obrisao video
    if (existingVideo === null && formData.id) {
      console.log('Deleting video');
      formDataToSend.append('deleteVideo', 'true');
    }

    // Dodavanje novog video fajla ako postoji
    if (videoFile) {
      formDataToSend.append('video', videoFile);
    }

    try {
      let response;
      if (formData.id) {
        console.log('Editing ad with ID:', formData.id);
        response = await api.patch(`/ads/${formData.id}`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          withCredentials: true,
        });
      } else {
        response = await api.post('/ads', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-Token': csrfToken,
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          withCredentials: true,
        });
      }

      const message = response.data.message || (formData.id ? 'Oglas uspješno ažuriran!' : 'Oglas uspješno dodan!');
      setSuccessMessage(message);
      setShow(false);

      const adsResponse = await api.get('/ads/user/own', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        withCredentials: true,
      });
      setAds(adsResponse.data);

      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Greška prilikom čuvanja oglasa:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Došlo je do greške prilikom čuvanja oglasa. Molimo pokušajte ponovo kasnije.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleHighlightAd = async (adId) => {
    setLoading(true);
    try {
      await api.patch(
        `/ads/${adId}/highlight`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'X-CSRF-Token': csrfToken,
          },
          withCredentials: true,
        }
      );
      const updatedAds = ads.map((ad) =>
        ad.id === adId ? { ...ad, isHighlighted: true } : ad
      );
      setAds(updatedAds);
      setSuccessMessage('Oglas uspješno istaknut.');
    } catch (error) {
      console.error('Greška prilikom isticanja oglasa:', error);
      setErrorMessage('Došlo je do greške prilikom isticanja oglasa. Molimo pokušajte ponovo kasnije.');
    } finally {
      setLoading(false);
    }
  };

  const fetchSubcategories = async (categoryId) => {
    setLoading(true);
    try {
      const response = await api.get(`/categories/${categoryId}/subcategories`);
      setSubcategories(response.data.filter(subcategory => subcategory.name !== 'no_subcategory'));
    } catch (error) {
      console.error('Greška prilikom dohvaćanja podkategorija:', error);
      setErrorMessage('Došlo je do greške prilikom dohvaćanja podkategorija. Molimo pokušajte ponovo kasnije.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h2 className="mt-4 text-center">Upravljanje Oglasima</h2>
      {/* Centralna navigacija */}
      {isPremium && (
        <div className="my-3">
          <UserDashboardNav />
        </div>
      )}

      {/* Prikaz poruke o grešci */}
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
          {errorMessage}
        </Alert>
      )}

      {/* Modal koji se prikazuje dok traje učitavanje */}
      <Modal show={showLoadingModal} backdrop="static" keyboard={false} centered>
        <Modal.Body className="text-center">
          <Spinner animation="border" variant="primary" className="mb-3" />
          <h5>Učitavam...</h5>
        </Modal.Body>
      </Modal>
      {loading && (
        <div className="text-center my-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {notifications.length > 0 && !loading && (
        <Alert variant="warning">
          <ul>
            {notifications.map((notification, index) => (
              <li key={index}>{notification}</li>
            ))}  
          </ul>
        </Alert>
      )}
      
      <div className="d-flex justify-content-end mb-3">
        {/* Dugme za dodavanje oglasa */}
        <Button onClick={() => handleShow()} className="me-2">Dodaj Oglas</Button>
        
        {/* Dugme za pregled oglasa */}
        <Button onClick={() => navigate('/')}>Pregled Oglasa</Button>
      </div>


      {!loading && ads.length === 0 ? (
        <Alert variant="info">Nemate postavljenih oglasa.</Alert>
      ) : (
        <div className="table-responsive">
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>ID</th>
                <th>Naslov</th>
                <th>Opis</th>
                <th>Cijena</th>
                <th>Ističe</th>
                <th>Kategorija</th>
                <th>Podkategorija</th>
                <th>Adresa</th>
                <th>Grad</th>
                <th>Glavna Slika</th>
                <th>Akcije</th>
              </tr>
            </thead>
            <tbody>
              {ads.map(ad => (
                <tr key={ad.id}>
                  <td data-label="ID">{ad.id}</td>
                  <td data-label="Naslov">{ad.title}</td>
                  <td data-label="Opis">{ad.description}</td>
                  <td data-label="Cijena">{ad.price}</td>
                  <td data-label="Ističe">{ad.expires_at}</td>
                  <td data-label="Kategorija">{ad.category ? ad.category.name : ''}</td>
                  <td data-label="Podkategorija">{ad.subcategory ? ad.subcategory.name : ''}</td>
                  <td data-label="Adresa">{ad.address}</td>
                  <td data-label="Grad">{ad.city}</td>
                  <td data-label="Glavna Slika">
                    {ad.images && ad.images.length > 0 ? (
                      (() => {
                        const primaryImage = ad.images.find(image => image.is_primary);
                        const imageUrl = primaryImage?.url
                          ? `${process.env.REACT_APP_BASE_URL}/photos/small/${primaryImage.url}`
                          : 'https://via.placeholder.com/100?text=No+Image';
                        return (
                          <Image
                            src={imageUrl}
                            alt={`Primary for ${ad.title}`}
                            thumbnail
                            style={{ width: '100px' }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = 'https://via.placeholder.com/100?text=No+Image';
                            }}
                          />
                        );
                      })()
                    ) : 'N/A'}
                  </td>

                  <td data-label="Akcije" className="d-flex justify-content-around">
                    <Button variant="warning" size="sm" onClick={() => handleShow(ad)} className="me-2">Uredi</Button>
                    <Button variant="danger" size="sm" onClick={() => handleDelete(ad.id)}>Izbriši</Button>
                    {isPremium && !ad.isHighlighted && (
                      <Button variant="primary" size="sm" onClick={() => handleHighlightAd(ad.id)} className="ms-2">Istakni</Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {/* Modal za dodavanje/uređivanje oglasa */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{/* Modal Title */}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Ovdje ide forma za dodavanje/uređivanje oglasa */}
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{formData.id ? 'Uredi Oglas' : 'Dodaj Oglas'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="title">
              <Form.Label><strong>Naslov</strong></Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label><strong>Opis</strong></Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label><strong>Cijena</strong></Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="expires_at">
              <Form.Label><strong>Ističe</strong></Form.Label>
              <Form.Control
                type="date"
                name="expires_at"
                value={formData.expires_at}
                onChange={handleExpiresAtChange}
                max={new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
              />
            </Form.Group>
            <Form.Group controlId="category_id">
              <Form.Label><strong>Kategorija</strong></Form.Label>
              <Form.Control
                as="select"
                name="category_id"
                value={formData.category_id}
                onChange={handleCategoryChange}
                required
              >
                <option value="">Izaberi Kategoriju</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="subcategory_id">
              <Form.Label><strong>Podkategorija</strong></Form.Label>
              <Form.Control
                as="select"
                name="subcategory_id"
                value={formData.subcategory_id}
                onChange={handleSubcategoryChange}
              >
                <option value="">Izaberi Podkategoriju</option>
                {subcategories.map(subcategory => (
                  <option key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="address">
              <Form.Label><strong>Adresa</strong></Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Label><strong>Grad</strong></Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="sea_view">
              <Form.Check
                type="checkbox"
                name="sea_view"
                label="Pogled na more"
                checked={formData.sea_view}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="mountain_view">
              <Form.Check
                type="checkbox"
                name="mountain_view"
                label="Pogled na planine"
                checked={formData.mountain_view}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="city_view">
              <Form.Check
                type="checkbox"
                name="city_view"
                label="Pogled na grad"
                checked={formData.city_view}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="near_restaurant">
              <Form.Check
                type="checkbox"
                name="near_restaurant"
                label="Blizina restorana"
                checked={formData.near_restaurant}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="near_park">
              <Form.Check
                type="checkbox"
                name="near_park"
                label="Blizina parka"
                checked={formData.near_park}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="near_beach">
              <Form.Check
                type="checkbox"
                name="near_beach"
                label="Blizina plaže"
                checked={formData.near_beach}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="has_wifi">
              <Form.Check
                type="checkbox"
                name="has_wifi"
                label="Wi-Fi"
                checked={formData.has_wifi}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="has_parking">
              <Form.Check
                type="checkbox"
                name="has_parking"
                label="Parking"
                checked={formData.has_parking}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="has_pool">
              <Form.Check
                type="checkbox"
                name="has_pool"
                label="Bazen"
                checked={formData.has_pool}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="has_gym">
              <Form.Check
                type="checkbox"
                name="has_gym"
                label="Teretana"
                checked={formData.has_gym}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="number_of_rooms">
              <Form.Label><strong>Broj Soba</strong></Form.Label>
              <Form.Control
                type="number"
                name="number_of_rooms"
                value={formData.number_of_rooms}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="number_of_bathrooms">
              <Form.Label><strong>Broj Kupatila</strong></Form.Label>
              <Form.Control
                type="number"
                name="number_of_bathrooms"
                value={formData.number_of_bathrooms}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="vehicle_type">
              <Form.Label><strong>Tip Vozila</strong></Form.Label>
              <Form.Control
                type="text"
                name="vehicle_type"
                value={formData.vehicle_type}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="has_gps">
              <Form.Check
                type="checkbox"
                name="has_gps"
                label="GPS"
                checked={formData.has_gps}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="has_child_seat">
              <Form.Check
                type="checkbox"
                name="has_child_seat"
                label="Sjedište za dijete"
                checked={formData.has_child_seat}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="has_insurance">
              <Form.Check
                type="checkbox"
                name="has_insurance"
                label="Osiguranje"
                checked={formData.has_insurance}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="has_driver">
              <Form.Check
                type="checkbox"
                name="has_driver"
                label="Vozač"
                checked={formData.has_driver}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="hunting_type">
              <Form.Label><strong>Tip Lovstva</strong></Form.Label>
              <Form.Control
                type="text"
                name="hunting_type"
                value={formData.hunting_type}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="has_hunting_guides">
              <Form.Check
                type="checkbox"
                name="has_hunting_guides"
                label="Lovci vodiči"
                checked={formData.has_hunting_guides}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="has_hunting_equipment">
              <Form.Check
                type="checkbox"
                name="has_hunting_equipment"
                label="Oprema za lov"
                checked={formData.has_hunting_equipment}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="has_lodging">
              <Form.Check
                type="checkbox"
                name="has_lodging"
                label="Smještaj"
                checked={formData.has_lodging}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group controlId="photos">
  <Form.Label><strong>Slike</strong></Form.Label>
  <Form.Control
    type="file"
    name="photos"
    onChange={handleFileChange}
    multiple
  />
  {!isPremium && (
    <Form.Text className="text-muted">
      Maksimalno 4 slike za obične korisnike.
    </Form.Text>
  )}

  {/* Prikaz dodanih slika s opcijom za postavljanje primarne slike */}
  {files.length > 0 && (
    <ul className="list-unstyled mt-3">
      {files.map((file, index) => {
        const objectUrl = URL.createObjectURL(file); // Prikaz nove slike
        return (
          <li key={index} className="d-flex align-items-center mb-2">
            <Image
              src={objectUrl}
              thumbnail
              style={{ maxWidth: '100px' }}
              alt={`New Image ${index + 1}`}
              onLoad={() => URL.revokeObjectURL(objectUrl)} // Oslobađa memoriju nakon učitavanja slike
            />
            <div className="ms-2">
              <Form.Check
                type="radio"
                name="primaryPhoto"
                checked={formData.primaryPhotoIndex === index} // Provjera da li je slika postavljena kao primarna
                onChange={() => handlePrimaryPhotoChange(index)}
                label="Primarna"
              />
            </div>
          </li>
        );
      })}
    </ul>
  )}
</Form.Group>

{existingVideo && existingVideo !== null ? (
  <Form.Group controlId="existingVideo">
    <Form.Label><strong>Postojeći Video</strong></Form.Label>
    <div className="d-flex align-items-center">
      {console.log(`Postojeći video dohvaćen iz: ${existingVideo}`)} {/* Dodaj log ovdje */}
      <video
        controls
        style={{ maxWidth: '200px' }}
        src={`${process.env.REACT_APP_BASE_URL}/storage/video/${existingVideo}`}// Možda za produkciju dodati storage ispred video
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = ''; // Postavi prazan src ako se video ne može učitati
        }}
      >
        Vaš preglednik ne podržava video tag.
      </video>
      <Button
        variant="danger"
        size="sm"
        className="ms-2"
        onClick={handleDeleteVideo} // Poziva funkciju za prikaz modala za brisanje videa
      >
        Izbriši Video
      </Button>
    </div>
  </Form.Group>
) : null}


      {/* Modal za potvrdu brisanja videa */}
      <Modal show={showConfirmDeleteVideoModal} onHide={() => setShowConfirmDeleteVideoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Potvrda brisanja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Da li ste sigurni da želite obrisati ovaj video?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmDeleteVideoModal(false)}>
            Ne
          </Button>
          <Button variant="danger" onClick={() => {
            if (deleteVideoConfirmationCallback) deleteVideoConfirmationCallback();
            setShowConfirmDeleteVideoModal(false);
          }}>
            Da, Obriši
          </Button>
        </Modal.Footer>
      </Modal>

<Form.Group controlId="video">
  <Form.Label><strong>Video</strong></Form.Label>
  <Form.Control
    type="file"
    name="video"
    accept="video/mp4,video/webm,video/ogg"
    onChange={handleVideoChange}
  />
</Form.Group>

{isPremium && (
  <>
    <Form.Group controlId="isHighlighted">
      <Form.Check
        type="checkbox"
        name="isHighlighted"
        label="Istakni Oglas"
        checked={formData.isHighlighted}
        onChange={(e) => setFormData({ ...formData, isHighlighted: e.target.checked })}
      />
    </Form.Group>
    <Form.Group controlId="autoRenew">
      <Form.Check
        type="checkbox"
        name="autoRenew"
        label="Automatski Obnovi Oglas"
        checked={formData.autoRenew}
        onChange={(e) => setFormData({ ...formData, autoRenew: e.target.checked })}
      />
    </Form.Group>
  </>
)}

<Form.Group controlId="primaryPhoto">
  <Form.Label><strong>Postojeće Slike</strong></Form.Label>
  <ul className="list-unstyled">
    {existingImages.map((image, index) => {
      const imageUrl = image.url
        ? `${process.env.REACT_APP_BASE_URL}/storage/photos/small/${image.url}` //za produkciju dodati storage ispred photos a za lokal obrisati storage
        : 'https://via.placeholder.com/100?text=No+Image';

      return (
        <li key={image.id} className="d-flex align-items-center mb-2">
          <Image
            src={imageUrl}
            thumbnail
            style={{ maxWidth: '100px' }}
            alt={`Ad ${index + 1}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'https://via.placeholder.com/100?text=No+Image';
            }}
          />
          <div className="ms-2">
            <Form.Check
              type="radio"
              name="primaryPhoto"
              checked={formData.primaryPhotoIndex === index} // Ovdje koristiš primaryPhotoIndex
              onChange={() => handlePrimaryPhotoChange(index)}
              label="Primarna"
            />
            <Button variant="danger" size="sm" onClick={() => handleImageDelete(image.id)} className="mt-2">Izbriši</Button>
          </div>
        </li>
      );
    })}
  </ul>
</Form.Group>


            <Button variant="primary" type="submit" className="mt-3 w-100">
              {formData.id ? 'Sačuvaj Promjene' : 'Dodaj Oglas'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal za potvrdu brisanja */}
      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Potvrda brisanja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Da li ste sigurni da želite obrisati ovaj oglas?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            Ne
          </Button>
          <Button variant="danger" onClick={() => {
            if (confirmationCallback) confirmationCallback();
            setShowConfirmationModal(false);
          }}>
            Da, Obriši
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal za prikaz poruke */}
      <Modal show={showMessageModal} onHide={() => setShowMessageModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Poruka</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{messageText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowMessageModal(false)}>
            U redu
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserManageAds;
