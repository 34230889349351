import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { authState, logout } = useAuth();

  useEffect(() => {
    if (!authState.isAuthenticated || authState.user.role !== 'administrator') {
      navigate('/admin/login');
    }
  }, [authState, navigate]);

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };

  const handleNavigate = (path) => {
    console.log(`Navigating to ${path}`);
    navigate(path);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-lg">
            <Card.Header className="bg-primary text-white text-center">
              <h2>Admin Dashboard</h2>
            </Card.Header>
            <Card.Body>
              <Row className="text-center">
                <Col>
                  <Button variant="primary" className="m-2 w-100" onClick={() => handleNavigate('/admin/manage-users')}>
                    Manage Users
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" className="m-2 w-100" onClick={() => handleNavigate('/admin/manage-ads')}>
                    Manage Ads
                  </Button>
                </Col>
              </Row>
              <Row className="text-center">
                <Col>
                  <Button variant="primary" className="m-2 w-100" onClick={() => handleNavigate('/admin/manage-categories')}>
                    Manage Categories
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" className="m-2 w-100" onClick={() => handleNavigate('/admin/manage-subcategories')}>
                    Manage Subcategories
                  </Button>
                </Col>
              </Row>
              {/* Novi red za statistiku */}
              <Row className="text-center">
                <Col>
                  <Button variant="info" className="m-2 w-100" onClick={() => handleNavigate('/admin/statistics/visits')}>
                    View Visit Statistics
                  </Button>
                </Col>
                <Col>
                  <Button variant="info" className="m-2 w-100" onClick={() => handleNavigate('/admin/statistics/reservations')}>
                    View Reservation Statistics
                  </Button>
                </Col>
              </Row>
              <Row className="text-center">
                <Col>
                  <Button variant="danger" className="m-2 w-100" onClick={handleLogout}>
                    Logout
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
