import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../AuthContext';
import { Table, Container } from 'react-bootstrap';
import api from '../api'; // Import api module

const VisitStatistics = () => {
  const { authState } = useAuth();
  const [visitData, setVisitData] = useState([]);
  const [adViewStats, setAdViewStats] = useState([]);
  const [totalVisits, setTotalVisits] = useState(0);
  const [adminStats, setAdminStats] = useState({});
  const [csrfToken, setCsrfToken] = useState('');

  const fetchCsrfToken = async () => {
    try {
      const response = await api.get('/csrf-token', { withCredentials: true });
      setCsrfToken(response.data.csrfToken);
      console.log('Fetched CSRF token:', response.data.csrfToken);
    } catch (error) {
      console.error('There was an error fetching the CSRF token!', error);
    }
  };

  const trackPageVisit = useCallback(async () => {
    const { user, role } = authState;

    if (user && user.sub) {
      try {
        const visitorData = {
          isAuthenticated: true,
          visitorId: user.sub,
          userId: user.sub,
          interests: '',
          role: role
        };

        const ipAddress = await api.get('/api/ipify').then(response => response.data.ip);
        const userAgent = navigator.userAgent;
        const visitTime = new Date().toISOString();

        console.log('Bilježenje posjete stranici:', visitorData);
        console.log('ID korisnika posjetioca kojeg šaljem u backend je:', user.sub);

        await api.post('/ad-visits/page-visits/track',
          { ...visitorData, ipAddress, userAgent, visitTime }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'X-CSRF-Token': csrfToken,
          },
          withCredentials: true
        });

        console.log('Posjeta uspješno zabilježena');
      } catch (error) {
        if (error.response) {
          console.error('Greška prilikom bilježenja posjete:', error.response.data);
          console.error('Status kod:', error.response.status);
          console.error('Zaglavlja:', error.response.headers);
        } else if (error.request) {
          console.error('Zahtjev nije dobio odgovor:', error.request);
        } else {
          console.error('Greška prilikom postavljanja zahtjeva:', error.message);
        }
        console.error('Kompletna greška:', error.config);
      }
    } else {
      console.error('Korisnik nije prijavljen, ne može se zabilježiti posjeta');
    }
  }, [authState, csrfToken]);

  const fetchStats = useCallback(async () => {
    const { user } = authState;

    if (user && user.sub) {
      try {
        console.log('Dohvatanje statistike za korisnika:', user.sub);

        const headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-CSRF-Token': csrfToken
        };

        const [visitResponse, adViewResponse, totalVisitsResponse, adminStatsResponse] = await Promise.all([
          api.get('/ad-visits/statistics/visit-counts', { headers, withCredentials: true }),
          api.get('/ad-visits/statistics/ad-view-stats', { headers, withCredentials: true }),
          api.get('/ad-visits/statistics/total-visits', { headers, withCredentials: true }),
          api.get('/ad-visits/statistics/admin-stats', { headers, withCredentials: true })
        ]);

        if (Array.isArray(visitResponse.data)) {
          setVisitData(visitResponse.data);
        } else {
          console.error('Expected an array but received:', visitResponse.data);
          setVisitData([]);
        }

        setAdViewStats(adViewResponse.data);
        setTotalVisits(totalVisitsResponse.data);
        setAdminStats(adminStatsResponse.data);

        console.log('Fetched data:', { visitData: visitResponse.data, adViewStats: adViewResponse.data, totalVisits: totalVisitsResponse.data, adminStats: adminStatsResponse.data });
      } catch (error) {
        console.error("Error fetching data:", error);
        setVisitData([]);
        setAdViewStats([]);
        setTotalVisits(0);
        setAdminStats({});
      }
    } else {
      console.error('Korisnik nije prijavljen, ne može se dohvatanje podataka');
    }
  }, [authState, csrfToken]);

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (csrfToken && authState.user) {
      trackPageVisit();
    }
  }, [csrfToken, trackPageVisit, authState.user]);

  useEffect(() => {
    if (authState.user && authState.user.sub) {
      console.log('Trenutni korisnik:', authState.user);
      fetchStats();
    }
  }, [authState.user, fetchStats]);

  const formatDate = (dateString) => {
    if (!dateString) return 'Invalid Date';
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options).split('/').join('.');
  };

  return (
    <Container className="mt-5">
      <h2>Visit Statistics</h2>
      {visitData.length === 0 ? (
        <p>No visit data available.</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Daily Count</th>
              <th>Monthly Count</th>
              <th>Yearly Count</th>
              <th>User Agent</th>
              <th>IP Address</th>
              <th>Visit Time</th>
            </tr>
          </thead>
          <tbody>
            {visitData.map((visit, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{formatDate(visit.date)}</td>
                <td>{visit.daily_count}</td>
                <td>{visit.monthly_count}</td>
                <td>{visit.yearly_count}</td>
                <td>{visit.user_agent}</td>
                <td>{visit.ip_address}</td>
                <td>{formatDate(visit.visit_time)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <h2>Ad View Statistics</h2>
      {adViewStats.length === 0 ? (
        <p>No ad view data available.</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Ad Title</th>
              <th>Total Views</th>
              <th>Unique Visitors</th>
              <th>Reservations</th>
            </tr>
          </thead>
          <tbody>
            {adViewStats.map((adStats, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{adStats.adTitle}</td>
                <td>{adStats.totalViews}</td>
                <td>{adStats.uniqueVisitors}</td>
                <td>
                  {adStats.reservations.length > 0 ? (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Reservation Date</th>
                          <th>User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {adStats.reservations.map((reservation, resIndex) => (
                          <tr key={resIndex}>
                            <td>{resIndex + 1}</td>
                            <td>{new Date(reservation.reservation_date).toLocaleString()}</td>
                            <td>{reservation.user.username}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No reservations available.</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <h2>Total Visits</h2>
      <p>{totalVisits}</p>

      <h2>Admin Statistics</h2>
      {Object.keys(adminStats).length === 0 ? (
        <p>No admin statistics available.</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Total Ads</th>
              <th>Total Users</th>
              <th>Total Administrators</th>
              <th>Total Visits</th>
              <th>Total Reservations</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{adminStats.totalAds}</td>
              <td>{adminStats.totalUsers}</td>
              <td>{adminStats.totalAdministrators}</td>
              <td>{adminStats.totalVisits}</td>
              <td>{adminStats.totalReservations}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default VisitStatistics;
