import React, { useEffect, useState } from 'react';
import { Table, Container, Card } from 'react-bootstrap';
import UserDashboardNav from './UserDashboardNav'; // Uvozimo navigacijsku komponentu
import api from './api'; // Ovisno o putanji do api modula
import './HighlightedAds.css'; // Uvozimo CSS stilove

const HighlightedAds = () => {
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true); // Stanje za prikazivanje indikatora učitavanja
  const [error, setError] = useState(''); // Stanje za prikaz greške

  useEffect(() => {
    const fetchHighlightedAds = async () => {
      setLoading(true); // Postavi učitavanje na true
      setError(''); // Resetuj grešku
      try {
        const response = await api.get('/ads/user/own', {
          headers: {
            'X-CSRF-Token': localStorage.getItem('csrfToken'),
          },
          withCredentials: true,
        });

        if (!response.data || !Array.isArray(response.data)) {
          throw new Error('Nevažeći odgovor sa servera.');
        }

        const highlightedAds = response.data.filter(ad => ad.isHighlighted);
        setAds(highlightedAds);
      } catch (error) {
        setError('Došlo je do greške prilikom dohvaćanja oglasa. Greška: ' + error.message);
      } finally {
        setLoading(false); // Učitavanje završeno
      }
    };

    fetchHighlightedAds();
  }, []);

  return (
    <Container className="mt-4">
      {/* Navigacijska komponenta na vrhu */}
      <UserDashboardNav />

      <Card className="mt-4 highlighted-ads-container">
        <Card.Header as="h2" className="text-center">
          Istaknuti Oglasi
        </Card.Header>
        <Card.Body>
          {loading ? (
            <p>Učitavanje oglasa...</p>
          ) : error ? (
            <p>{error}</p>
          ) : ads.length > 0 ? (
            <Table striped bordered hover responsive className="highlighted-ads-table">
              <thead className="table-dark">
                <tr>
                  <th>ID</th>
                  <th>Naslov Oglasa</th>
                  <th>Cijena</th>
                </tr>
              </thead>
              <tbody>
                {ads.map((ad) => (
                  <tr key={ad.id}>
                    <td data-label="ID">{ad.id}</td>
                    <td data-label="Naslov Oglasa">{ad.title}</td>
                    <td data-label="Cijena">{ad.price} KM</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-center">Nema istaknutih oglasa.</p>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default HighlightedAds;
