import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Spinner, Modal } from 'react-bootstrap'; // Import Modal
import api from '../api';
import { useAuth } from '../AuthContext';
import './ContactForm.css'; // Import CSS

const ContactForm = ({ adId, adOwnerEmail }) => {
  const { authState } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [csrfToken, setCsrfToken] = useState('');
  const [showModal, setShowModal] = useState(false); // Dodano stanje za prikaz modala
  const [modalMessage, setModalMessage] = useState(''); // Poruka unutar modala
  const [modalVariant, setModalVariant] = useState(''); // Tip poruke (uspjeh ili greška)

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!authState.isAuthenticated) {
      setModalMessage('Morate biti prijavljeni da biste kontaktirali vlasnika oglasa.');
      setModalVariant('danger');
      setShowModal(true);
      return;
    }

    setSubmitted(true);
    setShowModal(false); // Zatvori modal ako je bio otvoren

    try {
      await api.post('/contact', {
        adId,
        adOwnerEmail,
        name,
        email,
        message,
      }, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true,
      });

      setModalMessage('Poruka je uspješno poslana!');
      setModalVariant('success');
      setShowModal(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('There was an error sending the message!', error);
      setModalMessage('Došlo je do greške prilikom slanja poruke. Pokušajte ponovo.');
      setModalVariant('danger');
      setShowModal(true);
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <>
      <Container fluid className="mt-4">
        <Row className="justify-content-md-center">
          <Col md={12}>
            <h6 className="text-center mb-4"><strong>Kontaktirajte vlasnika oglasa ako imate pitanja</strong></h6>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Ime:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Unesite Vaše ime"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Unesite Vaš email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Poruka:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Unesite poruku"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </Form.Group>
              <div className="d-grid gap-2 mt-4">
                <Button variant="primary" type="submit" disabled={submitted} size="lg">
                  {submitted ? (
                    <>
                      <Spinner animation="border" size="sm" role="status" className="me-2" />
                      Sending...
                    </>
                  ) : (
                    'Pošalji poruku'
                  )}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>

      {/* Modal za prikaz poruke o uspješnosti ili grešci */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered className="custom-message-modal">
        <Modal.Header closeButton>
          <Modal.Title>{modalVariant === 'success' ? 'Uspjeh' : 'Greška'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={modalVariant === 'success' ? 'bg-success text-white' : 'bg-danger text-white'}>
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Zatvori
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactForm;
