import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import UserProfile from './UserProfile';
import AdminProfile from './AdminProfile';
import { useAuth } from './AuthContext';
import './NavigationBar.css';

const NavigationBar = () => {
  const { authState, loading, logout } = useAuth();
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('NavigationBar: Current authState:', authState);
  }, [authState]);

  const handleToggle = () => setExpanded(!expanded);
  const handleLinkClick = () => setExpanded(false);

  const handleLogout = () => {
    logout();
    setExpanded(false); // Zatvara meni
    navigate('/login'); // Preusmjerava korisnika na login stranicu
  };

  if (loading) {
    return null;
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg" expanded={expanded} onToggle={handleToggle} className="mb-4">
      <Container>
        <Link to="/" className="navbar-brand" onClick={handleLinkClick}>
          Početna
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {authState?.isAuthenticated && authState.user?.role === 'administrator' && (
              <Link to="/admin/dashboard" className="nav-link" onClick={handleLinkClick}>
                Administrator
              </Link>
            )}
            {authState?.isAuthenticated && authState.user?.role !== 'administrator' && (
              <Link to="/dashboard" className="nav-link" onClick={handleLinkClick}>
                Upravljačka ploča
              </Link>
            )}
          </Nav>
          <Nav className="ms-auto">
            {authState?.isAuthenticated ? (
              <div className="custom-navbar-profile-icon">
                {authState.user?.role === 'administrator' ? (
                  <AdminProfile onLogout={handleLogout} closeMenu={() => setExpanded(false)} />
                ) : (
                  <UserProfile onLogout={handleLogout} closeMenu={() => setExpanded(false)} />
                )}
              </div>
            ) : (
              <>
                <Link to="/register" className="nav-link" onClick={handleLinkClick}>
                  <Button variant="outline-light" className="me-2">Registracija</Button>
                </Link>
                <Link to="/login" className="nav-link" onClick={handleLinkClick}>
                  <Button variant="outline-light" className="me-2">Prijava</Button>
                </Link>
                <Link to="/contact" className="nav-link" onClick={handleLinkClick}>
                  <Button variant="outline-light">Kontakt</Button>
                </Link>
                <Link to="/admin/login" className="nav-link" onClick={handleLinkClick}>
                  <Button variant="outline-light" className="me-2">Admin</Button>
                </Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
