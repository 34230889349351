import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Form, Modal, Alert, Image } from 'react-bootstrap';
import api from '../api';

const ManageSubcategories = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    description: '',
    categoryId: '',
    imageUrl: ''
  });
  const [file, setFile] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await api.get('/categories/get-all-subcategories', {
          withCredentials: true,
        });
        console.log('Subcategories fetched:', response.data);
        setSubcategories(response.data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
        setErrorMessage('Error fetching subcategories.');
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await api.get('/categories', {
          withCredentials: true,
        });
        console.log('Categories fetched:', response.data);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setErrorMessage('Error fetching categories.');
      }
    };

    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', {
          withCredentials: true,
        });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
        setErrorMessage('Error fetching CSRF token.');
      }
    };

    fetchSubcategories();
    fetchCategories();
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/categories/subcategories/${id}`, {
        withCredentials: true,
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      });
      setSubcategories(subcategories.filter(subcategory => subcategory.id !== id));
      setSuccessMessage('Subcategory deleted successfully.');
    } catch (error) {
      console.error('Error deleting subcategory:', error);
      setErrorMessage('Error deleting subcategory.');
    }
  };

  const handleShow = (subcategory = {}) => {
    setFormData({
      id: subcategory.id || '',
      name: subcategory.name || '',
      description: subcategory.description || '',
      categoryId: subcategory.category ? subcategory.category.id : '',
      imageUrl: subcategory.imageUrl || ''
    });
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFile(null);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('categoryId', formData.categoryId);
    if (file) {
      formDataToSend.append('image', file);
    }

    try {
      if (formData.id) {
        await api.patch(`/categories/subcategories/${formData.id}`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-Token': csrfToken,
          },
          withCredentials: true,
        });
        setSuccessMessage('Subcategory updated successfully.');
      } else {
        await api.post(`/categories/${formData.categoryId}/subcategories`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-Token': csrfToken,
          },
          withCredentials: true,
        });
        setSuccessMessage('Subcategory created successfully.');
      }
      setShow(false);
      const response = await api.get('/categories/get-all-subcategories', {
        withCredentials: true,
      });
      setSubcategories(response.data);
    } catch (error) {
      console.error('Error saving subcategory:', error);
      setErrorMessage('Error saving subcategory.');
    }
  };

  return (
    <Container>
      <h2 className="mt-4">Manage Subcategories</h2>
      <Button className="mb-3" onClick={() => handleShow()}>Add Subcategory</Button>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Category</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(subcategories) && subcategories.map(subcategory => (
            <tr key={subcategory.id}>
              <td>{subcategory.id}</td>
              <td>{subcategory.name}</td>
              <td>{subcategory.description}</td>
              <td>{subcategory.category ? subcategory.category.name : ''}</td>
              <td>
                {subcategory.imageUrl ? (
                  <Image 
                    src={`${process.env.REACT_APP_BASE_URL}/slike_podkategorije/${subcategory.imageUrl}`} 
                    rounded 
                    style={{ width: '100px' }} 
                    onError={(e) => { 
                      e.target.src = 'https://via.placeholder.com/100';
                      e.target.alt = 'No Image Available';
                    }} 
                  />
                ) : (
                  'No Image'
                )}
              </td>
              <td>
                <Button variant="warning" onClick={() => handleShow(subcategory)}>Edit</Button>{' '}
                <Button variant="danger" onClick={() => handleDelete(subcategory.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{formData.id ? 'Edit Subcategory' : 'Add Subcategory'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="categoryId">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="categoryId"
                value={formData.categoryId}
                onChange={handleChange}
                required
              >
                <option value="">Select Category</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileChange}
              />
            </Form.Group>
            {formData.imageUrl && (
              <div className="mb-3">
                <Image 
                  src={`${process.env.REACT_APP_BASE_URL}/slike_podkategorije/${formData.imageUrl}`} 
                  rounded 
                  style={{ width: '100px' }} 
                  onError={(e) => { 
                    e.target.src = 'https://via.placeholder.com/100';
                    e.target.alt = 'No Image Available';
                  }} 
                />
              </div>
            )}
            <Button variant="primary" type="submit" className="mt-3">
              {formData.id ? 'Save Changes' : 'Add Subcategory'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ManageSubcategories;
