import React, { useEffect, useState } from 'react';
import { useAuth } from '../components/AuthContext';
import { Table, Container, Card } from 'react-bootstrap';
import UserDashboardNav from './UserDashboardNav'; // Import navigacijske komponente
import api from './api'; // Import API module
import './ReservationDetails.css'; // Import CSS for this component

const ReservationDetails = () => {
  const { authState } = useAuth();
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const fetchReservations = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await api.get(`/reservations/user/${authState.user.sub}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setReservations(response.data);
      } catch (error) {
        console.error('Error fetching reservations:', error);
      }
    };

    if (authState.user && authState.user.sub) {
      fetchReservations();
    }
  }, [authState.user]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/\//g, '.');
  };

  return (
    <Container className="mt-4">
      {/* Navigacijska komponenta na vrhu */}
      <UserDashboardNav />

      <Card className="mt-4 reservation-container">
        <Card.Header as="h2" className="text-center">
          Detalji Rezervacija
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive className="reservation-table">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Oglas</th>
                <th>Datum početka</th>
                <th>Datum završetka</th>
                <th>Ukupna cijena</th>
              </tr>
            </thead>
            <tbody>
              {reservations.map((reservation) => (
                <tr key={reservation.id}>
                  <td data-label="ID">{reservation.id}</td>
                  <td data-label="Oglas">{reservation.ad.title}</td>
                  <td data-label="Datum početka">{formatDate(reservation.start_date)}</td>
                  <td data-label="Datum završetka">{formatDate(reservation.end_date)}</td>
                  <td data-label="Ukupna cijena">{reservation.total_price} KM</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ReservationDetails;
