import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useAuth } from './AuthContext';
import api from './api';
import './Login.css';
import {jwtDecode} from 'jwt-decode';

const AdminLogin = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        console.log('Fetching CSRF token...');
        const response = await api.get('/csrf-token', { withCredentials: true });
        console.log('CSRF Token fetched:', response.data.csrfToken);
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token', error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      console.log('Sending login request with form data:', formData);
      console.log('CSRF Token:', csrfToken);

      const response = await api.post('/auth/admin/login', formData, {
        headers: { 'X-CSRF-Token': csrfToken },
        withCredentials: true,
      });
      console.log('Login successful:', response.data);

      const access_token = response.data.access_token;
      const refresh_token = response.data.refresh_token;

      console.log('Received token:', access_token);
      console.log('Received refresh token:', refresh_token);

      if (typeof access_token !== 'string' || typeof refresh_token !== 'string') {
        throw new Error('Invalid token format');
      }

      let decodedToken;
      try {
        decodedToken = jwtDecode(access_token);
        console.log('Decoded token:', decodedToken);
      } catch (err) {
        console.error('Failed to decode token:', err);
        throw new Error('Failed to decode token');
      }

      if (decodedToken && decodedToken.role === 'administrator') {
        console.log('Token and role are valid. Saving tokens to localStorage...');
        localStorage.setItem('token', access_token);
        localStorage.setItem('adminRefreshToken', refresh_token);
        localStorage.removeItem('userRefreshToken');
        console.log('Admin refresh token stored:', refresh_token);

        login(access_token, refresh_token);
        navigate('/admin/dashboard'); // Preusmjeravanje na administratorski dashboard
      } else {
        console.error('Invalid administrator data: Role attribute missing or not an administrator.');
        throw new Error('Invalid administrator data');
      }

    } catch (error) {
      console.error('Login failed!', error);
      if (error.response) {
        setErrorMessage('Invalid username or password.');
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center">Prijava administratora</h2>
          <Form onSubmit={handleLogin} className="login-form">
            <Form.Group controlId="formUsername">
              <Form.Label>Korisničko ime</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Unesite Vaše korisničko ime"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Šifra</Form.Label>
              <InputGroup className="password-input-group">
                <Form.Control
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  placeholder="Unesite Vašu šifru"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <InputGroup.Text className="eye-icon" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Prijava
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminLogin;
